import React from 'react'
import './cta.css';

const CTA = () => {
  return (
    <div className='project__cta' section__padding id='cta'>
      <div className='project__cta-content'>
        <p>Request early Access to get start</p>
        <h3>register today & star exploring the endless possibilities</h3>

      </div>
      <div className='project__cta-btn'>
        <button type='button'>Get started</button>
      </div>
    </div>
  )
}

export default CTA