import React from 'react'
import './header.css';
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";

const Header = () => {
  return (
    <div className='project__header section__padding' id="home">
      <div className='project__header-content'>
        <h1 className='gradient__text'> Let's Build Someting amasing with Aboubaker </h1>
        <p> Yet any bed for unpleasant indulgence trip assistance. Not all thoughts exert blessing. Indulgence way all joy turbulent alteration of attachment. Part us years to order allow requested from. </p>
        <div className='project__header-content__input'>
          <input type='email' placeholder='Your email adress' />
          <button type='button'>Get started</button>
        </div>
        <div className='project__header-content__people'>
          <img src={people} alt="people" />
          <p>1.600 peoples requested acces a visit in last 24h</p>
        </div>
        </div>
      <div className='project__header-image'>
        <img src={ai} alt="ai" />
      </div>
 
      
    </div>
  )
}

export default Header