import React from 'react'
import { Header, Footer, Blog , Possibility , Whatproject , Features } from './container';
import { CTA , Brand , Navbar } from './component';
import './App.css';


const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
      <Brand />
      <Whatproject />
      <Features />
      <Possibility />
      <CTA />
      <Blog />
      <Footer />

        
    </div>
  )
}

export default App