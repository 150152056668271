import React from 'react'
import {Article} from '../../component/';
import './blog.css';
import { blog01 , blog02 , blog03 , blog04 , blog05} from './import'


const Blog = () => {
  return (
    <div className='project__blog section__padding 'id="blog">
      <div className='project__blog-heading'>
        <h1 className='gradient__text'>A lot is happening, We are blogging about it</h1>

      </div>
      <div className='project__blog-container'>
        <div className='project__blog-container_groupA'>
          <Article imgUrl={blog01} date="sep 26,2022"  title="AI is the future open it and explore how is it"/>
        </div>
        <div className='project__blog-container_groupB'>
        <Article imgUrl={blog02} date="sep 26,2022" title="AI is the future open it and explore how is it"/>
        <Article imgUrl={blog03} date="sep 26,2022"  title="AI is the future open it and explore how is it"/>
        <Article imgUrl={blog04} date="sep 26,2022"  title="AI is the future open it and explore how is it"/>
        <Article imgUrl={blog05} date="sep 26,2022"  title="AI is the future open it and explore how is it"/>
        </div>
      </div>
      
      </div>
  )
}

export default Blog