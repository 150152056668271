import React from 'react'
import './footer.css';
import logo1 from '../../assets/logo1.png'

const Footer = () => {
  return (
    <div className='project__footer section__padding'>
      <div className='project__footer-heading'>
        <h1 className='gradient__text'>Do you want to step in to the future.let us explore how is it</h1 >
      </div>
      <div className='project__footer-btn'>
        <p>Request Early Access</p>
      </div>
      <div className='project__footer-links'>
        <div className='project__footer-links_logo'>
          <img src={logo1} alt="logo" />
          <p>Beni yadjis Jijel,All right reserved for Aboubaker</p>
        </div>
        <div className='project__footer-links_div'>
          <h4 >Links</h4>
          <p>Overons </p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>

        </div>
        <div className='project__footer-links_div'>
          <h4 >Company</h4>
          <p>terms && conditions</p>
          <p>Privacy Policy </p>
          
          <p>Contact</p>

        </div>
        <div className='project__footer-links_div'>
          <h4 >Get in touch</h4>
          <p>Boudria beni yadjis jijel</p>
          <p>(438)402-7604</p>
          <p>Baker.g@live.fr</p>
        </div>
      </div>
      <div className='project__footer-copyright'>
        <p>2023-Aboubaker, All right reserved</p>
      </div>
    </div>
  )
}

export default Footer