import React from 'react'
import './possibility.css';

import possibilityImage from '../../assets/possibility.png'

const Possibility = () => {
  return (
    <div className='project__possibility section__padding 'id='possibility'>
      <div className='project__possibility-image'>
        <img src={possibilityImage} alt="possibility" />


      </div>
      <div className='project__possibility-content'>
        <h4>Request early Access to get start</h4>
        <h1 className='gradient__text'>
        The possibilities are beyond your imagination
        </h1>
        <p>Yet any bed for unpleasant indulgence trip assistance. Not all thoughts exert blessing. Indulgence way all joy turbulent alteration of attachment. Part us years to order allow requested from.</p>
        <h4>Request early Access to get start</h4>
      </div>
      
      </div>
  )
}

export default Possibility